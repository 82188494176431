


.galleyWrapper{
     display: grid;
     grid-template-columns: 1fr 1fr 1fr;
     grid-gap:8px;
     margin-top:16px;
}

.header{
    color:#00AB55;
    /* margin-top: 20px; */
}

.cardContent-padding{
    padding: 4px 24px !important;
}

img{
    height: auto;
    cursor: pointer;
}

img:hover{
    border: 1px solid green;
}

.unselect{
    color:red;
    cursor: pointer;
    width: fit-content;
}

.finalsave{
    text-align: center;
    margin-top: 12px;
    width: 100%;

}

.save{
    width:50%;
}