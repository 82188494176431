.activityListWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  margin-top: 4px;
}

h6 {
  margin-top: 6px;
  color: #68c2e8;
  font-size: 14px;
}

.header {
  color: #00ab55;
}

.cardContent-padding {
  padding: 4px 24px !important;
}

label {
  padding: 4px 0px;
  margin: 20px 0px 4px 0px;
}

textarea {
  height: 250px;
  width: 100%;
  padding: 0px 4px;
}

input {
  height: 44px;
  border-radius: 5px;
  /* border: none; */
  border: 1px solid rgb(235, 232, 232);
  padding: 0px 4px;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: Georgia, serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-controls {
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.addactivity-wrapper {
  margin-top: 16px;
}

.max-height {
  max-height: 300px;
  overflow: auto;
}

.selected {
  border: 3px solid red !important;
}

.addVariants {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 6px;
  margin-top: 8px;
}

.textarea-wrapper {
  margin-top: 6px;
}
.variant-dropdown-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 13px;
  width: 100%;
  grid-gap: 4px;
}

.close {
  text-align: right;
  cursor: pointer;
}

/* overwrite css */
.css-1k7sk4d-MuiGrid-root {
  /* width of add varient element*/
  max-width: 100% !important;
}
.css-ot7hnh-MuiGrid-root {
  /* width of add varient element*/
  max-width: 100% !important;
}
.DraftEditor-editorContainer {
  height: 300px !important;
}

.DraftEditor-root {
  height: 200px;
  border: 1px solid;
}
.MuiCardContent-root {
  display: flex;
  flex-direction: column;
}
.css-l99c4a-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  /* font-size of dropdown*/
  font-size: 12px;
}

.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  max-width: 200px !important;
}
/* overwrite css */
